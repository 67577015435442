export * from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer.styles';

export const Container = {
  position: 'relative',
  width: '100%',
  height: '70vh',
  maxHeight: '70vh',
  h1: {
    margin: 0,
    // fontSize: [2, 'xx-large'],
    lineHeight: ['21px', 'normal'],
    fontWeight: 'bold',
  },
};
