/** @jsx jsx */
import Box from '@fify.at/gatsby-theme-base/src/components/Box/Box';
import Button from '@fify.at/gatsby-theme-base/src/components/Button';
import Flex from '@fify.at/gatsby-theme-base/src/components/Flex/Flex';
import { Container } from '@fify.at/gatsby-theme-base/src/components/Grid/index';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import HeroSection from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSection';
import Image from 'gatsby-image';
import React from 'react';
import { Slide, Swiper } from 'react-dynamic-swiper';
import { jsx } from 'theme-ui';
import {
  ButtonBox,
  Container as ContainerStyles,
  ContentContainer,
  ContentContainerWrapper,
  SubtitleBox,
  TitleBox,
} from './HeroSectionContainer.styles';

export default function HeroSectionContainer({
  fluid = false,
  image,
  orientation,
  variant,
  title,
  subtitle,
  buttons = [],
  images,
}) {
  const content = (
    <Content
      image={image}
      images={images}
      orientation={orientation}
      variant={variant}
      title={title}
      subtitle={subtitle}
      buttons={buttons}
      fluidVariant={fluid ? 'fluid' : 'nonfluid'}
    />
  );

  if (fluid) {
    return (
      <Section
        container="none"
        sx={{
          ...ContainerStyles,
          variant: 'layout.HeroSectionContainer.Container',
        }}
      >
        {content}
      </Section>
    );
  } else {
    return (
      <HeroSection>
        <Container
          sx={{
            ...ContainerStyles,
            variant: 'layout.HeroSectionContainer.Container',
          }}
        >
          {content}
        </Container>
      </HeroSection>
    );
  }
}

function Content({
  image,
  orientation,
  variant,
  title,
  subtitle,
  fluidVariant,
  buttons,
  images,
}) {
  let titleElement = title;
  let subtitleElement = subtitle;

  if (variant === 'alpha') {
    titleElement = <h1>{title}</h1>;
    subtitleElement = (
      <h1>
        <strong>{subtitle}</strong>
      </h1>
    );
  }

  if (variant == 'alphaText') {
    titleElement = <h1>{title}</h1>;
  }

  if (variant === 'blocks') {
    titleElement = <h1 sx={{ margin: 0 }}>{title}</h1>;
    subtitleElement = (
      <h1 sx={{ margin: 0 }}>
        <strong>{subtitle}</strong>
      </h1>
    );
  }

  let [firstLoaded, setFirstLoaded] = React.useState(false);

  return (
    <div sx={{ height: '100%' }}>
      <div sx={{ height: '100%' }}>
        {image ? (
          <Image {...image.childImageSharp} style={{ position: null }} />
        ) : images && images.length ? (
          <Swiper
            navigation={false}
            pagination={false}
            swiperOptions={{
              autoplay: firstLoaded ? { delay: 5000 } : false,
              observer: true,
              slidesPerView: 'auto',
            }}
            sx={{
              '.swiper-container': {
                height: ['55vh', '55vh', '80vh'],
                maxHeight: ['55vh', '55vh', '80vh'],
                zIndex: -1,
              },
            }}
          >
            {images.map((item, index) => (
              <Slide key={index}>
                {(firstLoaded || index === 0) && (
                  <Image
                    fluid={item.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    onLoad={() => {
                      if (index === 0) {
                        setFirstLoaded(true);
                      }
                    }}
                    css={{
                      height: '100%',
                      maxHeight: '100%',
                      '.swiper-slide': {
                        height: '100%',
                        maxHeight: '100%',
                        zIndex: -1,
                      },
                    }}
                  />
                )}
              </Slide>
            ))}
          </Swiper>
        ) : (
          undefined
        )}
      </div>
      <div sx={ContentContainerWrapper[fluidVariant][variant]}>
        <Flex sx={ContentContainer[orientation]}>
          <Box
            sx={{
              ...TitleBox[fluidVariant][variant],
              variant: 'layout.HeroSectionContainer.FirstBox',
            }}
          >
            {titleElement}
          </Box>
          <Box
            sx={{
              ...SubtitleBox[fluidVariant][variant],
              variant: 'layout.HeroSectionContainer.SecondBox',
            }}
          >
            {subtitleElement}
          </Box>
          <Box
            sx={{
              ...ButtonBox[fluidVariant][variant],
              variant: 'layout.HeroSectionContainer.ThirdBox',
            }}
          >
            {buttons.map(b => (
              <Button variant="primary" to={b.to}>
                {b.text}
              </Button>
            ))}
          </Box>
        </Flex>
      </div>
    </div>
  );
}
